<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Feature' : 'Create Feature'" />
        <div>
          <md-button :to="{ name: 'Estate Features' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="md-layout" @submit.prevent="validateFeature">
          <md-field :class="getValidationClass('first_name')">
            <label>Title</label>
            <md-input v-model="form.title" required></md-input>
            <span class="md-error" v-if="!$v.form.title.required"
              >Title is required</span
            >
          </md-field>
          <md-field :class="getValidationClass('icon')">
            <label>Icon</label>
            <md-input v-model="form.icon"></md-input>
            <span class="md-error">Icon is required</span>
          </md-field>
          <md-field v-if="metadata">
            <label for="parent_id">Type Category</label>
            <md-select v-model="form.type_id" name="parent_id" id="parent_id">
              <md-option value="" disabled>Select Type Category</md-option>
              <md-option
                v-for="(type, id) in metadata.types"
                :key="id"
                :value="id"
                >{{ type }}</md-option
              >
            </md-select>
          </md-field>
          <mdc-button :loading="loading">{{
            id ? "Update " : "Create"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("realtor");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      icon: "fa-circle-notch",
    },
    loading: false,
    metadata: null,
  }),
  validations: {
    form: {
      title: {
        required,
      },
    },
  },
  methods: {
    ...mapActions([
      "createFeature",
      "updateFeature",
      "getFeature",
      "getFormData",
    ]),
    async validateFeature() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };

        try {
          this.loading = true;
          let call = this.id
            ? this.updateFeature(data)
            : this.createFeature(data);
          let msg = this.id ? "Feature Updated" : "Feature Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Estate Features" });
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    async getData() {
      const data = await this.getFormData();
      this.metadata = data;
    },
  },
  mounted() {
    this.getData();
    if (this.id) {
      this.getFeature(this.id).then((type) => {
        this.form = {
          title: type.title,
          id: type.id,
        };
      });
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-Types {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
